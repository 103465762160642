import React from "react";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ComponentGuard from "../../../../components/shared/guards/component.guard";

const MaterialsTable = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } =
    props;

  const { search, sortclassroom } = useParams();
  let navigate = useNavigate();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const sortClassRoom = sortclassroom.slice(
    sortclassroom.indexOf(":") + 1,
    sortclassroom.length
  );

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    navigate(
      `/materials/page:${number}/search:${searchKeyword}/sortclassroom:${sortClassRoom}`
    );
    setLoading(false);
  };

  return (
    <div className="table-container">
      <MaterialsTableHead />
      <>
        <ul className="table-container__body">
          {ArrayData.map((item) => {
            return (
              <li key={item.id}>
                <MaterialsTableBody Item={item} />
              </li>
            );
          })}
        </ul>
        <Paginate
          handlePageClick={handlePageClick}
          perpage={perpage}
          pagenumber={pagenumber}
          itemPerpage={PageSize}
          setItemPerpage={setPageSize}
        />
      </>
    </div>
  );
};

export default MaterialsTable;

const MaterialsTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span>رمز المادة</span>
        <span>اسم المادة</span>
        <span>رمز الدورة</span>

        <span>اسم الدورة</span>

        <span>رقم الدورة</span>
        <span>الإجراءات</span>
      </li>
    </ul>
  );
};

const MaterialsTableBody = (props) => {
  const { Item } = props;
  return (
    <>
      <span>{Item.code}</span>
      <span>{Item.nameAr}</span>
      <span>{Item.classRoomCode}</span>

      <span>{Item.classRoomNameAr}</span>

      <span>{Item.classRoomNumber}</span>
      <span>
        <ComponentGuard requiredRoles={["ViewTestStudents"]}>
          <NavLink
            // to={`/questionbanck/add-all/page:1/${Item.id}`}
            // to={`/students/page:1/search:/sortclasscode:${Item.classRoomCode}/sortclassroomnamber:${Item.classRoomNumber}`}
            to={`/materials/material-subject/page:1/search:/subjectId:${Item.id}/classroomId:${Item.classRoomId}/classroomNumber:${Item.classRoomNumber}`}
            className="btn btn-show"
            title="الطلاب الممتحنين"
          >
            <img src="/assets/icons/eye-show.svg" alt="edit" />
          </NavLink>
        </ComponentGuard>
      </span>
    </>
  );
};

function Paginate(props) {
  const { handlePageClick, perpage, pagenumber, itemPerpage, setItemPerpage } =
    props;
  const SelectValue = [
    {
      id: 1,
      value: 10,
      title: "10 صفوف",
    },
    {
      id: 2,
      value: 20,
      title: "20 صفوف",
    },
    {
      id: 3,
      value: 30,
      title: "30 صفوف",
    },
    {
      id: 4,
      value: 40,
      title: "40 صفوف",
    },
    {
      id: 5,
      value: 50,
      title: "50 صفوف",
    },
  ];

  return (
    <div className="paginate_control">
      <div className="paginate_control__select">
        <label htmlFor="">
          <span>عدد الصفوف</span>
        </label>
        <select
          className="form-select"
          onChange={(e) => {
            setItemPerpage(parseInt(e.target.value));
          }}
          value={itemPerpage}
        >
          <>
            {SelectValue.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </>
        </select>
      </div>
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  );
}
