import React from "react";
import ModalAddAllQuestion from "./modal-add-question";
import { useNavigate, useParams } from "react-router";
import axiosInstance from "../../../../api/axios_instance";
import swal from "sweetalert";
import { Api } from "../../../../api";
import ComponentGuard from "../../../../components/shared/guards/component.guard";

const AddAllQuestionTop = () => {
  const { id } = useParams();

  return (
    <>
      <div className="gap-30 justify-content-end d-flex align-items-center w-100">
        <ComponentGuard requiredRoles={["AddQuestion"]}>
          <button
            type="button"
            className="btn btn-download"
            data-bs-toggle="modal"
            data-bs-target="#ModalAddAllQuestion"
          >
            <span className="icon">
              <img src="/assets/icons/icon-download.svg" alt="" />
            </span>
            <span className="text">رفع ملف اكسيل</span>
          </button>
        </ComponentGuard>

        <button
          type="button"
          className="btn"
          style={{ background: "#FFE2E5", color: "#f64e60", fontSize: "4px" }}
          data-bs-toggle="modal"
          data-bs-target={`#ModalDeleteAllSubjectQuestions-${id}`}
        >
          <span className="icon" style={{ margin: "0px 12px" }}>
            <img src="/assets/icons/icon-delete.svg" alt="remove" />
          </span>
          <span className="text">مسح الكل</span>
        </button>
        <ComponentGuard requiredRoles={["AddQuestion"]}>
          <QuestionDropdown />
        </ComponentGuard>
        <ModalDeleteAllSubjectQuestions id={id} />
      </div>
      <ModalAddAllQuestion />
    </>
  );
};

export default AddAllQuestionTop;

const QuestionDropdown = () => {
  let navigate = useNavigate();
  const { id } = useParams();

  const handleCLick = () => {
    navigate(`/questionbanck/add-question/subjectid:${id}`);
  };

  return (
    <div className="dropdown">
      <button
        type="button"
        onClick={handleCLick}
        className="btn btn-primary-blue btn-show-dropdown"
      >
        <div>
          <Icon />
          <span className="text">إضافة سؤال </span>
        </div>
      </button>
    </div>
  );
};

const Icon = () => {
  return (
    <span className="icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
      >
        <path
          d="M12.5 5V19"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 12H19.5"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

const ModalDeleteAllSubjectQuestions = (props) => {
  const { id } = props;
  return (
    <div
      className="modal fade modal-edite-exams"
      id={`ModalDeleteAllSubjectQuestions-${id}`}
      tabIndex="-1"
      aria-labelledby="ModalDeleteAllSubjectQuestions"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="header">
            <h6>هل تريد حذف جميع اسئلة المادة ؟</h6>
          </div>

          <div className="form-group-button">
            <button
              type="button"
              className="btn btn-line-susees"
              data-bs-dismiss="modal"
            >
              إلغاء
            </button>
            <button
              type="button"
              className="btn btn-submit"
              onClick={(e) => {
                e.preventDefault();
                DeleteAllQuestions(id);
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const DeleteAllQuestions = async (id) => {
  const options = {
    method: "post",
    url: `${Api}Question/DeleteAllSubjectQuestions`,
    headers: Headers,
    data: { id },
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
        return;
      } else {
        swal("تم الحذف بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      }
    })
    .catch(function (error) {});
};
