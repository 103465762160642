import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import IconSearch from "../../../components/shared/icon-search";
import ComponentGuard from "../../../components/shared/guards/component.guard";

const ExamsTop = (props) => {
  const { setLoading } = props;
  let navigate = useNavigate();
  const { search: searchKey } = useParams();
  const searchVal = searchKey.split(":")[1];
  const [search, setSearch] = useState(searchVal ?? "");
  const SearchClick = (e) => {
    e.preventDefault();
    navigate(`/exams/page:${1}/search:${search}`);
    if (e.target.value !== searchVal) setLoading(false);
  };

  return (
    <div className="exams-content__title pt-9">
      <div className="add_data justify-content-end d-flex w-100">
        <ComponentGuard requiredRoles={["AddTest"]}>
          <NavLink className="btn btn-add " to={`/exams/add`}>
            <span className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M12.5 5V19"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.5 12H19.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className="text">إضافة اختبار جديد</span>
          </NavLink>
        </ComponentGuard>
      </div>

      <br />
      <div className="d-flex justify-content-between align-items-center w-100">
        <h5 className="size-h5">الاختبارات</h5>

        <div className="question-content__top__filter">
          <div className="input-group-search">
            <IconSearch SearchClick={SearchClick} />
            <input
              type="text"
              placeholder="بحث"
              defaultValue={search}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  navigate(`/exams/page:${1}/search:${e.target.value}`);
                  if (e.target.value !== searchVal) setLoading(false);
                }
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamsTop;
/*
<div className='exams-content__title '>
        <div className="add_data justify-content-end d-flex w-100">
            <NavLink className="btn btn-repetiton"  to={`/exams/repetiton/${Id}/subjectid:${subjectId}/classroomid:${classroom}`}>
                <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M12.5 5V19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.5 12H19.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                <span className="text">
                    إعادة الاختبار
                </span>
            </NavLink>
            <NavLink className="btn btn-add " to={`/exams/add/${subjectId}/classroomid:${classroom}`}>
                <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M12.5 5V19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M5.5 12H19.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                <span className="text">
                    إضافة اختبار جديد
                </span>
            </NavLink>
        </div>

        <br />
        <div className="d-flex justify-content-between align-items-center w-100">
            <h5 className='size-h5'>قائمة الطلاب</h5>
        </div>
    </div>
*/
