import React from "react";
import { useSelector } from "react-redux";

function ComponentGuard({ requiredRoles, isRequireAll = false, children }) {
  const user = useSelector((state) => state.user);
  const roles = user.roles;
  return (
    <>
      {(
        !isRequireAll
          ? roles.some((role) => requiredRoles.includes(role))
          : roles.every((role) => requiredRoles.includes(role))
      )
        ? children
        : ""}
    </>
  );
}

export default ComponentGuard;
